<template>
  <div>
    <!-- <calendar> -->
    <date-picker
      mode="single"
      :attributes="attributes"
      :masks="{
        title: 'YYYY MMMM', 
      }"
      :is-inline="true"
      @dayclick="changeDateTime"
      @update:to-page="onChangePage"
      :select-attribute = "selectAttribute"
      :is-expanded="false"
      style="width: 100%; border: none; border-radius: 0px; min-height: 960px"
    >
    </date-picker>
    <!-- </calendar> -->
  </div>
</template>
<script>
// import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
    components: {
        // Calendar,
        datePicker: DatePicker
    },
    props: {
        attributes: {
            type: Array,
            default: () => {
                return []
            }
        },
        isMonth: {
            type: Boolean,
            default: () => {
                return false
            }
        }
    },
    data: () => ({
        onlyUpdateCalendar: false,
        fromPage: {
            month: 1,
            year: 2020
        },
        toPage: {
            month: 12,
            year: 2020
        },
        selectAttribute: {
            highlight: {
                color: 'pink',
                fillMode: 'light',
            }
        },
        countChangePage: 0
    }),
    computed: {
        layout() {
            return this.$screens(
                {
                // Default layout for mobile
                default: {
                    columns: 1,
                    rows: 1,
                    isExpanded: true,
                },
                // Override for large screens
                lg: {
                    columns: 2,
                    rows: 2,
                    isExpanded: false,
                },
                },
            );
        }
    },
    methods: {
        onChangePage: function (date) {
            this.countChangePage += 1
            if (!this.onlyUpdateCalendar) {
                if (!this.isMonth) {
                    if (this.fromPage.year < date.year) {
                        this.fromPage.year = date.year + 1
                        this.toPage.year = date.year + 1
                    } else if (this.fromPage.year > date.year) {
                        this.fromPage.year = date.year - 1
                        this.toPage.year = date.year - 1
                    }
                }
                this.onlyUpdateCalendar = true
                if (this.countChangePage == 1) {
                    this.$emit('onPageChange', date)
                } else {
                    this.countChangePage = 0
                    this.$emit('onPageChange', date)
                }
            }
        },
        changeDateTime: function (date) {
            this.$emit('changeDateTime', date)
        },
        resetCount: function () {
            setTimeout(() => {
                this.onlyUpdateCalendar = false
            }, 0.5)
        }
    },
    mounted() {
    }
}
</script>